import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Slider from "../components/slider"
import Seo from "../components/seo"
import ReactLinkify from "react-linkify"
import PostList from "../components/post_list_alpha"

const DRR = ({ data }) => {

  const slider_data = data.allMdx.nodes.map((x) =>  x);

  const first_video = slider_data.shift();

  const metaData = [{
    name: "keywords",
    content: first_video.frontmatter.keywords,
  }];

  const image = getImage(first_video.fields.thumbnail);

  const description = "Watch me cook recipes from Alison Roman's Nothing Fancy cookbook.";

  return (
    <Layout>
      <Seo title="Nothing Fancy" meta={metaData} image={image} description={description}/>
      <h1>Nothing Fancy</h1>
      <p><a href="https://www.alisoneroman.com/">Alison Roman</a> is a chef, food writer, and cookbook
        author. She began her career at <a href="https://www.bonappetit.com/">Bon Appétit</a>, where she
        became a senior editor, then moved to <a href="https://www.buzzfeed.com/food">BuzzFeed Food</a>.
        She begam working with <a href="https://cooking.nytimes.com/">New York Times Cooking</a> in 2018,
        where she worked through 2020.</p>
      <p>She has also had several recipes go viral on social media, including a chickpea stew, a shallot
        pasta, and a cookie recipe that may be one of the most enticing recipes I've seen in her
        books so far.</p>
      {first_video && (
        <div className="featured-video">
          <h2>{first_video.frontmatter.title}</h2>
          <Link to={first_video.fields.slug}>
            {first_video.fields.thumbnail && 
              <GatsbyImage image={getImage(first_video.fields.thumbnail)} alt={first_video.frontmatter.title + ' from ' + first_video.frontmatter.source}/> 
            }
          </Link>
          <figcaption>
            {first_video.frontmatter.description && (
              <ReactLinkify>{first_video.frontmatter.description.split('\n').shift()}</ReactLinkify>
            )}
          </figcaption>
        </div>
      )}
      {slider_data.length > 0 &&  (
      <div className="video-slider">
        <h2>More Recent Videos</h2>
        <Slider data={slider_data} />
      </div>)}
      <div className="all-videos">
        <h2>All Videos</h2>
        <PostList data={data.allMdx.nodes} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMdx(
      filter: { frontmatter: { keywords: { regex: "/nothing fancy/" } } }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      nodes {
        fields {
          youtubeId
          slug
          thumbnail {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH)
            }
          }
        }
        frontmatter {
          title
          source
          date(formatString: "MMMM D, Y")
          video_url
          description
          keywords
        }
      }
    }
  }
`

export default DRR;
